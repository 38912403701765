import './App.css';
import DecisionTree from './components/decision';
import Welcome from './components/welcome';
import Contact from './components/Contact'
function App() {
  return (
    
<>
    <Welcome />
    
    </>
  );
}

export default App;
