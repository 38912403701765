import React, { useState, useEffect } from "react";
import "./decision.css";
import { motion } from "framer-motion";
import { Job } from "../data/test1";
import { vacation } from "../data/test2";
import Contact from "./Contact";

const DecisionTree = () => {
  const [currentNode, setCurrentNode] = useState(null);
  const [previousNodes, setPreviousNodes] = useState([]);
  const [responses, setResponses] = useState([]);
  const [outcome, setOutcome] = useState(null);

  const handleAnswer = (answer) => {
    const nextNode = currentNode.answers[answer.toLowerCase()];

    setResponses([...responses, { question: currentNode.question, answer }]);

    if (typeof nextNode === "string") {
      setOutcome(nextNode);
    } else if (nextNode.text) {
      setOutcome(nextNode.text);
    } else {
      setPreviousNodes([...previousNodes, currentNode]);
      setCurrentNode(nextNode);
    }
  };

  const handleBack = () => {
    if (previousNodes.length === 0) {
      return;
    }

    const newPreviousNodes = [...previousNodes];
    const newResponses = [...responses];
    newResponses.pop();

    setOutcome(null);
    setPreviousNodes(newPreviousNodes);
    setResponses(newResponses);
    if (outcome == null) {
      setCurrentNode(newPreviousNodes.pop());
    }

    console.log("Back to node:", currentNode);
  };

  const handleSelectionChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "null") {
      setCurrentNode(null);
    } else if (selectedValue === "Job") {
      setCurrentNode(Job);
    } else if (selectedValue === "Vacation") {
      setCurrentNode(vacation);
    }
    setPreviousNodes([]);
    setResponses([]);
    setOutcome(null);
  };

  return (
    <>
      <div className="main">
        <div className="choice_Tree">
          <select onChange={handleSelectionChange}>
            <option value="null">Select an option</option>
            <option value="Job">Job</option>
            <option value="Vacation">Vacation</option>
          </select>
        </div>
        <div className="main-decision">
          <div id="conversation-container">
            {responses.map((res, index) => (
              <div key={index}>
                {res.question && (
                  <p>
                    <strong style={{ color: "#72efdd" }}>Q:</strong>{" "}
                    {res.question}
                  </p>
                )}
                <p>
                  <strong style={{ color: "#f72585" }}>A:</strong>{" "}
                  {res.answer.charAt(0).toUpperCase() +
                    res.answer.slice(1, res.answer.length)}
                </p>
              </div>
            ))}
          </div>
          {outcome ? (
            <div key="outcome">
              <p>
                <strong style={{ color: "#00f5d4" }}>Outcome:</strong> {outcome}
              </p>
            </div>
          ) : currentNode ? (
            <div>
              <div id="question-container">
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1 }}
                >
                  <strong style={{ color: "#72efdd" }}>Q:</strong>{" "}
                  {currentNode.question}
                </motion.p>
                {Object.keys(currentNode.answers).map((answer) => (
                  <motion.button
                    key={answer}
                    onClick={() => handleAnswer(answer)}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                  >
                    {answer.charAt(0).toUpperCase() +
                      answer.slice(1, answer.length)}
                  </motion.button>
                ))}
              </div>
            </div>
          ) : (
            <div>Please select an option to start.</div>
          )}

          {currentNode ? (
            <div id="navigation-buttons">
              <motion.button
                id="back"
                style={{
                  marginLeft: "50%",
                  marginTop: "50px",
                  marginBottom: "10px",
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 2 }}
                onClick={handleBack}
                disabled={previousNodes.length === 0}
              >
                Back
              </motion.button>
            </div>
          ) : (
            <br />
          )}
        </div>
      </div>
      <Contact responses={responses} />
    </>
  );
};

export default DecisionTree;
