export const vacation= {
    "question": "Do you prefer a domestic or international vacation?",
    "answers": {
      "domestic": {
        "question": "Do you prefer urban or rural destinations?",
        "answers": {
          "urban": {
            "question": "Which region do you prefer?",
            "color": "blue",
            "answers": {
              "Northeast": {
                "text": "You should visit New York City.",
                "color": "red",
              },
              "South": {
                "text": "You should visit Miami.",
                "color": "blue",
              },
              "West": {
                "text": "You should visit Los Angeles.",
                "color": "green",
              },
              "Midwest": {
                "text": "You should visit Chicago.",
                "color": "yellow",
              },
            },
          },
          "rural": {
            "question": "What type of rural area do you prefer?",
            "color": "orange",
            "answers": {
              "mountains": {
                "text": "You should visit the Rocky Mountains.",
                "color": "purple",
              },
              "beach": {
                "text": "You should visit the Outer Banks.",
                "color": "brown",
              },
              "forest": {
                "text": "You should visit the Great Smoky Mountains.",
                "color": "pink",
              },
              "desert": {
                "text": "You should visit the Mojave Desert.",
                "color": "green",
              },
            },
          },
        },
      },
      "international": {
        "question": "Which continent are you interested in?",
        "answers": {
          "Europe": {
            "question": "Do you prefer Western or Eastern Europe?",
            "color": "red",
            "answers": {
              "Western": {
                "text": "You should visit Paris, France.",
                "color": "blue",
              },
              "Eastern": {
                "text": "You should visit Prague, Czech Republic.",
                "color": "green",
              },
            },
          },
          "Asia": {
            "question": "Do you prefer Southeast Asia or East Asia?",
            "color": "yellow",
            "answers": {
              "Southeast Asia": {
                "text": "You should visit Bangkok, Thailand.",
                "color": "purple",
              },
              "East Asia": {
                "text": "You should visit Tokyo, Japan.",
                "color": "pink",
              },
            },
          },
          "Africa": {
            "question": "Do you prefer Northern or Southern Africa?",
            "color": "orange",
            "answers": {
              "Northern": {
                "text": "You should visit Marrakech, Morocco.",
                "color": "brown",
              },
              "Southern": {
                "text": "You should visit Cape Town, South Africa.",
                "color": "blue",
              },
            },
          },
          "South America": {
            "question": "Do you prefer the Andes or the Amazon?",
            "color": "green",
            "answers": {
              "Andes": {
                "text": "You should visit Machu Picchu, Peru.",
                "color": "red",
              },
              "Amazon": {
                "text": "You should visit the Amazon Rainforest.",
                "color": "blue",
              },
            },
          },
        },
      },
    },
  }
  