import { useEffect } from "react";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import CursorBlinker from "../utilities/cursor";
import './welcome.css'
import Counter from "../utilities/counter";
import DecisionTree from './decision.js'
import Contact from "./Contact";

const Welcome = () => {
  const baseText1 = "Welcome to Releveld";
  const baseText2 = "Your friendly consultant partner";
  const baseText3 = "At Releveld, we are dedicated to providing top-notch consulting services tailored to your unique needs. Our team of experts is here to guide you through every step of your journey, ensuring your success and growth. Discover the difference with Releveld, where your goals become our mission.";

  const count1 = useMotionValue(0);
  const count2 = useMotionValue(0);
  const count3 = useMotionValue(0);

  const rounded1 = useTransform(count1, (latest) => Math.round(latest));
  const rounded2 = useTransform(count2, (latest) => Math.round(latest));
  const rounded3 = useTransform(count3, (latest) => Math.round(latest));

  const displayText1 = useTransform(rounded1, (latest) =>
    baseText1.slice(0, latest)
  );
  const displayText2 = useTransform(rounded2, (latest) =>
    baseText2.slice(0, latest)
  );
  const displayText3 = useTransform(rounded3, (latest) =>
    baseText3.slice(0, latest)
  );

  useEffect(() => {
    const controls1 = animate(count1, baseText1.length, {
      type: "tween",
      duration: 2,
      ease: "easeInOut",
    });

    controls1.then(() => {
      setTimeout(() => {
        const controls2 = animate(count2, baseText2.length, {
          type: "tween",
          duration: 2,
          ease: "easeInOut",
        });

        controls2.then(() => {
          setTimeout(() => {
            const controls3 = animate(count3, baseText3.length, {
              type: "tween",
              duration: 6, // Adjust the duration as needed
              ease: "easeInOut",
            });

            return controls3.stop;
          },2000); // 500ms delay before starting the paragraph animation

          return controls2.stop;
        });
      }, 1500); // 500ms delay before starting the subtitle animation

      return controls1.stop;
    });
  }, []);

  return (
    <>
    <div className="homePage" >
    <nav>
      <ul>
        <li>Home</li>
        <li>Inquiries</li>
        <li>Contact</li>
      </ul>


    </nav>
    <div className="main-container">
      <h1>
        <motion.span>{displayText1}</motion.span>
        <CursorBlinker />
      </h1>
      <h4>
        <motion.span>{displayText2}</motion.span>
        <CursorBlinker />
      </h4>
      <p>
        <motion.span>{displayText3}</motion.span>
        <CursorBlinker />
      </p>
    </div>
    <Counter />
    </div>

    <DecisionTree />

    </>
  );
};

export default Welcome;
