 export const Job = {
    question: "Are you a business owner or a student?",
    answers: {
      "business owner": {
        question: "Do you have employees?",
        answers: {
          yes: {
            question: "How many employees do you have?",
            color: "blue",
            answers: {
              "1-10": {
                text: "You have a small business.",
                color: "red",
              },
              "11-50": {
                text: "You have a medium-sized business.",
                color: "blue",
              },
              "50+": {
                text: "You have a large business.",
                color: "green",
              },
            },
          },
          no: {
            text: "You are a sole proprietor.",
            color: "yellow",
          },
        },
      },
      student: {
        question: "Are you a high school or college student?",
        answers: {
          "high school": {
            question: "Do you plan to go to college?",
            color: "orange",
            answers: {
              yes: {
                text: "You are on a college-bound path.",
                color: "purple",
              },
              no: {
                text: "You might want to explore vocational training.",
                color: "brown",
              },
            },
          },
          college: {
            question: "What is your major?",
            color: "pink",
            answers: {
              science: {
                text: "You are pursuing a degree in science.",
                color: "red",
              },
              arts: {
                text: "You are pursuing a degree in arts.",
                color: "blue",
              },
              other: {
                text: "You are pursuing a degree in another field.",
                color: "green",
              },
            },
          },
        },
      },
    },
  };