import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Contact.css';

const Contact = ({ responses = [] }) => {
    const [from, setFrom] = useState("");
    const [subject, setSubject] = useState("");
    const [text, setText] = useState("Description");

    useEffect(() => {
        if (responses.length > 0) {
            const formattedResponses = responses.map(res => `${res.question}: ${res.answer}`).join('\n');
            setText(`Description\n${formattedResponses}`);
        }
    }, [responses]);

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            await axios.post('http://localhost:5000/send-email', {
                from: from,
                subject: subject,
                text: text
            });
            alert('Email sent successfully!');
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to send email.');
        }
    }

    return (
        <div className='contact-form'>
            <form onSubmit={handleSubmit}>
                <input 
                    type="email" 
                    name="from" 
                    placeholder='Enter your Email Address...' 
                    onChange={(e) => setFrom(e.target.value)} 
                    value={from}
                    required 
                />
                <input 
                    type="text" 
                    name="subject" 
                    placeholder='Subject' 
                    onChange={(e) => setSubject(e.target.value)} 
                    value={subject}
                    required 
                />
                <textarea 
                    name="text" 
                    placeholder='Text' 
                    onChange={(e) => setText(e.target.value)} 
                    value={text}
                    required 
                ></textarea>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default Contact;
