




 import React, { useEffect } from 'react';
import { motion, useMotionValue, useTransform, animate } from 'framer-motion';
import './counter.css'
import time from '../images/time.svg'
import customer from '../images/customer.svg'
const Counter = () => {
  
  const count = useMotionValue(0);
  const count1=useMotionValue(0);
const rounded = useTransform(count, latest => Math.round(latest));
  const rounded1 = useTransform(count1, latest => Math.round(latest));
  useEffect(() => {
    const controls = animate(count, 100, {
      type: "tween",
      duration: 10, 
      ease: "easeInOut",
    });
    const controls1 = animate(count1, 5, {
        type: "tween",
        duration: 6, 
        ease: "easeInOut",
      });
  
    return () => controls.stop();
  }, [count]);

  return (
  <>
  <div className='counter-container'>
    <table>
      <tr>
        <td>
        <img src={customer} style={{ width: "60px", height: "60px", objectFit: "contain",fontWeight:"700",marginBottom:'-50px'}} />
        </td>
        <td><img src={time} style={{ width: "30px", height: "30px", objectFit: "contain",fontWeight:"700",marginBottom:'-40px'}} /></td>
      </tr>
      <tr>
        <td>  <motion.div className='counter'>{rounded}</motion.div></td>
        <td><motion.div className='counter'>{rounded1}</motion.div></td>
      </tr>
      <tr>
        <td className='desc-counter'>Happy customers</td>
        <td className='desc-counter'>Years of Experience</td>
      </tr>
    </table>

  
  

</div>
</>
  );
};

export default Counter;
 